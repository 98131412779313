import React from 'react';

import { Container, Grid, Paper, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import VerticalPadding from '../global/vertical-padding';

import { DevelopmentIcon, HostingIcon, ManagementIcon } from '../svg/icons';

const features = [
  {
    icon: <DevelopmentIcon />,
    heading: 'Expert Web Development',
    description:
      'Take your website from concept to reality with our streamlined development process and launch more engaging digital experiences — on time and on budget.',
  },
  {
    icon: <HostingIcon />,
    heading: 'Industry-Leading Hosting',
    description:
      'Minimize load times, maximize uptimes, and enable unrivaled content and design scalability with our fully managed, high-performance hosting services.',
  },
  {
    icon: <ManagementIcon />,
    heading: 'Proactive Ongoing Management',
    description:
      'Go beyond reactive web maintenance options with our ongoing, proactive monitoring, optimization, backup, and management services that enable iterative content and design enhancements.',
  },
];

const useFeatureStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  feature: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      '&:nth-child(n+2)': {
        position: 'relative',
        '&::before': {
          backgroundColor: 'white',
          content: '""',
          height: '100%',
          left: 0,
          maxHeight: 180,
          opacity: 0.15,
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 1,
        },
      },
    },
  },
  sizer: {
    margin: 'auto',
    maxWidth: 410,
  },
  icon: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '100%',
    display: 'flex',
    height: 135,
    justifyContent: 'center',
    margin: 'auto',
    width: 135,
    '& img, & svg': {
      display: 'block',
      maxHeight: 64,
      maxWidth: 64,
    },
  },
  heading: {
    marginTop: theme.spacing(7),
    textAlign: 'center',
  },
}));

const HomeFeatures: React.FC = () => {
  const featureStyles = useFeatureStyles();

  return (
    <Paper className={featureStyles.root}>
      <VerticalPadding>
        <Container>
          <Grid container spacing={10} justify="center">
            {features.map((feature, index) => (
              <Grid
                item
                key={index}
                className={featureStyles.feature}
                xs={12}
                sm={6}
                lg={4}
              >
                <div className={featureStyles.sizer}>
                  <div className={featureStyles.icon}>{feature.icon}</div>
                  <Typography
                    className={featureStyles.heading}
                    variant="h3"
                    color="textSecondary"
                    gutterBottom={true}
                  >
                    {feature.heading}
                  </Typography>
                  <Typography variant="body2">{feature.description}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </VerticalPadding>
    </Paper>
  );
};

export default HomeFeatures;
