import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import Button from '../components/mui/button';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';
import HomeBanner from '../components/layouts/home-banner';
import HomeFeatures from '../components/layouts/home-features';
import Testimonial from '../components/layouts/testimonial';
import CallToAction from '../components/layouts/call-to-action';
import MNLogo from '../images/svg/marchnetworks-logo.svg';

const HomePage: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Enterprise-Grade Web Hosting, Development, and Management"
        description="An expert web operations team providing streamlined enterprise-grade fully integrated web services."
      />
      <HomeBanner />
      <LightMode>
        <VerticalPadding lg>
          <Container maxWidth={false}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography variant="h2" color="textSecondary">
                  make your website a more efficient marketing tool
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="subtitle1" component="p" className="lead">
                  Eliminate the inefficiencies of standalone web solutions and
                  services with one integrated web operations package.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </LightMode>
      <HomeFeatures />
      <Box>
        <VerticalPadding>
          <Container maxWidth={false}>
            <Typography
              variant="h3"
              align="center"
              color="textSecondary"
              gutterBottom={true}
            >
              All for one monthly price
            </Typography>
            <Typography
              variant="subtitle2"
              component="p"
              align="center"
              gutterBottom={true}
            >
              Get the fully integrated Marvel &amp; Snap web
              operations-as-a-service experience with the monthly package that’s
              right for you.
            </Typography>
            <Box pt={3} textAlign="center">
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                to="/pricing/"
              >
                Explore Pricing
              </Button>
            </Box>
          </Container>
        </VerticalPadding>
      </Box>
      <Testimonial
        author="Jackie Lawrence, Global Leader of Marketing at March Networks"
        logo={MNLogo}
      >
        It’s very valuable to March Networks to have Marvel{' '}
        <span style={{ fontSize: '0.8em' }}>&amp;</span> Snap as an extension of
        our marketing team. They help us to really put our best foot forward
        when it comes to our website, digital products, and digital marketing.
      </Testimonial>
      <CallToAction imageSrc="call-to-action-laptop-couch.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '8em' }}
        >
          Move to the next level
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Develop, launch, manage, and iterate digital marketing assets faster.
          And bring advanced digital experiences to your audience more quickly
          and efficiently.
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          to="/contact/"
        >
          Let&apos;s talk
        </Button>
      </CallToAction>
    </Layout>
  );
};

export default HomePage;
