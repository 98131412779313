import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Grid, Paper } from '@material-ui/core';
import VerticalPadding from '../global/vertical-padding';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

const useCallToActionStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  content: {
    margin: 'auto',
    marginBottom: 40,
    [theme.breakpoints.up('md')]: {
      order: 1,
      marginBottom: 'auto',
    },
  },
  imageContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
    [theme.breakpoints.up('md')]: {
      order: 2,
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      '&__inner': {
        bottom: 0,
        height: '100%',
        minWidth: '60vw',
        position: 'absolute',
        zIndex: 2,
        width: '100%',
      },
    },
  },
  image: {
    height: '100%',
    minWidth: '60vw',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  m: {
    bottom: 0,
    height: '80%',
    minWidth: '60vw',
    opacity: 0.3,
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    '& .gatsby-image-wrapper': {
      height: '100%',
      width: '100%',
      '& img': {
        bottom: 0,
        objectPosition: 'right bottom',
        top: 'unset',
      },
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(10) / 2,
      '& .gatsby-image-wrapper': {
        '& img': {
          objectPosition: 'left bottom',
        },
      },
    },
  },
}));

interface CallToActionProps {
  children: React.ReactNode;
  imageSrc: string;
  imageOpacity?: number;
  m?: boolean;
}

const CallToAction: React.FC<CallToActionProps> = ({
  children,
  imageSrc,
  imageOpacity,
  m,
}) => {
  const callToActionStyles = useCallToActionStyles();

  const imageData = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { regex: "/png|jpg/" } }) {
          nodes {
            base
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    `
  );

  const imageMatch = useMemo(
    () =>
      imageData.allFile.nodes.find((n) => n.base === imageSrc).childImageSharp
        .gatsbyImageData,
    [imageData, imageSrc]
  );

  const image = getImage(imageMatch);

  return (
    <Paper className={callToActionStyles.root}>
      <Container>
        <Grid container spacing={10} alignItems="stretch">
          <Grid
            item
            className={callToActionStyles.imageContainer}
            xs={12}
            md={6}
            lg={7}
            xl={8}
          >
            <div className={`${callToActionStyles.imageContainer}__inner`}>
              {image ? (
                <GatsbyImage
                  className={callToActionStyles.image}
                  image={image}
                  layout="fullWidth"
                  objectFit="cover"
                  alt=""
                  style={{ opacity: imageOpacity }}
                />
              ) : null}
              {image && m ? (
                <div className={callToActionStyles.m}>
                  <StaticImage
                    src="../../images/svg/dark-m-logo.svg"
                    placeholder="blurred"
                    layout="constrained"
                    objectFit="contain"
                    aspectRatio={1158 / 684}
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            className={callToActionStyles.content}
            xs={12}
            md={6}
            lg={5}
            xl={4}
          >
            <VerticalPadding>{children}</VerticalPadding>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

CallToAction.defaultProps = {
  imageOpacity: 0.5,
  m: true,
};

export default CallToAction;
