import React from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import VerticalPadding from '../global/vertical-padding';

const useTestimonialStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
  },
  quote: {
    fontFamily: 'Visby CF',
    textTransform: 'unset',
  },
  icon: {
    marginBottom: theme.spacing(8),
    height: 'auto',
  },
  author: {
    fontWeight: 600,
    marginTop: theme.spacing(4),
  },
}));

interface TestimonialProps {
  children: React.ReactNode;
  author: string;
  logo: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
  children,
  author,
  logo,
}) => {
  const testimonialStyles = useTestimonialStyles();

  return (
    <Box bgcolor="primary.main" textAlign="center">
      <VerticalPadding>
        <Container>
          <Grid container justify="center">
            <Grid
              item
              className={testimonialStyles.root}
              xs={12}
              lg={10}
              xl={6}
            >
              <img
                className={testimonialStyles.icon}
                src={logo}
                alt=""
                width="200"
                height="43"
              />
              <Typography
                className={testimonialStyles.quote}
                variant="h2"
                component="blockquote"
              >
                &ldquo;{children}&rdquo;
                <Typography
                  className={testimonialStyles.author}
                  variant="body2"
                  component="footer"
                >
                  &mdash;&nbsp;{author}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </VerticalPadding>
    </Box>
  );
};

export default Testimonial;
