import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '../mui/button';
import { Link } from 'gatsby';
import overlayLogo from '../../images/svg/home-banner-overlay-logo.svg';

const useHomeBannerStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
  },
  root: {
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundImageContainer: {
    height: '100%',
    left: 0,
    position: 'absolute',
    zIndex: 1,
    '& [class*="MuiGrid-container"], & .gatsby-image-wrapper': {
      height: '100%',
    },
    '& .gatsby-image-wrapper': {
      marginLeft: -7,
      width: 'calc(100% + 7px)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  h1: {
    maxWidth: 900,
    paddingTop: 80,
  },
  h2: {
    margin: '0.95em 0',
    maxWidth: 650,
  },
  image: {
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'initial',
  },
  bannerContent: {
    position: 'relative',
    zIndex: 3,
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  callToAction: {
    marginTop: theme.spacing(7),
    marginBottom: 0,
    position: 'relative',
    zIndex: 4,
  },
  callToActionContent: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    position: 'relative',
    '&::after': {
      backgroundColor: theme.palette.primary.main,
      content: '""',
      display: 'block',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100vw',
      zIndex: 1,
    },
    '& [class*="MuiTypography"]': {
      maxWidth: 460,
      position: 'relative',
      zIndex: 2,
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      maxWidth: 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      '& [class*="MuiTypography"]': {
        maxWidth: 'unset',
      },
    },
  },
  callToActionArrow: {
    backgroundColor: theme.palette.primary.dark,
    height: 220,
    maxWidth: '220px !important',
    padding: '0 !important',
    position: 'relative',
    width: 220,
    '& > a': {
      alignItems: 'center',
      display: 'flex',
      height: 220,
      justifyContent: 'center',
      width: 220,
    },
    transition: 'background-color 0.2s ease-out',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.only('sm')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  iconWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    '&:hover': {
      '& > div > div': {
        width: 50,
      },
    },
  },
  icon: {
    width: 36,
  },
  arrow: {
    position: 'relative',
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.common.white,
    transition: 'width 0.2s ease-out',
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      width: 30,
      height: 2,
      right: -6,
      backgroundColor: theme.palette.common.white,
    },
    '&::after': {
      top: -10,
      transform: 'rotate(45deg)',
    },
    '&::before': {
      top: 10,
      transform: 'rotate(-45deg)',
    },
  },
  overlayLogoContainer: {
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    zIndex: 2,
    '& [class*="MuiGrid-container"]': {
      height: '100%',
    },
    '& [class*="MuiGrid-item"]': {
      overflow: 'hidden',
      position: 'relative',
    },
    ['@media (max-width: 1500px)']: {
      display: 'none',
    },
  },
  overlayLogoMid: {
    maxWidth: 220,
  },
  overlayLogo: {
    flexGrow: 1,
    marginLeft: -7,
    maxWidth: 'unset',
    '& img': {
      bottom: '0',
      height: 'auto',
      left: 0,
      position: 'absolute',
      width: 1275,
    },
  },
}));

const HomeBanner: React.FC = () => {
  const homeBannerStyles = useHomeBannerStyles();

  return (
    <Paper className={homeBannerStyles.paper}>
      <Container className={homeBannerStyles.root} maxWidth={false}>
        <Container className={homeBannerStyles.backgroundImageContainer}>
          <Grid container spacing={0} justify="flex-end">
            <Grid item xs={12} md={7}>
              <StaticImage
                src="../../images/home-banner.jpg"
                placeholder="blurred"
                objectFit="cover"
                alt=""
                style={{ opacity: 0.7 }}
              />
            </Grid>
          </Grid>
        </Container>
        <Grid container className={homeBannerStyles.bannerContent} spacing={7}>
          <Grid item xs={12} md={9} xl={7}>
            <Typography className={homeBannerStyles.h1} variant="h1">
              Create. Deliver. Manage.
            </Typography>
            <Typography
              className={homeBannerStyles.h2}
              variant="h2"
              color="textSecondary"
            >
              More Agile and Effective Digital Experiences
            </Typography>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              to="/how-it-works/"
            >
              How It Works
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          className={homeBannerStyles.callToAction}
          spacing={10}
          justify="flex-start"
          wrap="nowrap"
        >
          <Grid
            item
            className={homeBannerStyles.callToActionContent}
            xs={6}
            md={7}
            lg={5}
          >
            <Typography variant="subtitle1" component="p">
              Fully integrated enterprise-grade web services. One fixed monthly
              cost.
            </Typography>
          </Grid>
          <Grid
            item
            className={homeBannerStyles.callToActionArrow}
            xs={6}
            md={2}
          >
            <Link
              to="/pricing/"
              className={homeBannerStyles.iconWrapper}
              aria-label="view pricing"
            >
              <div className={homeBannerStyles.icon}>
                <div className={homeBannerStyles.arrow}></div>
              </div>
            </Link>
          </Grid>
        </Grid>
        <Container className={homeBannerStyles.overlayLogoContainer}>
          <Grid container justify="flex-start" wrap="nowrap">
            <Grid item md={5}>
              &nbsp;
            </Grid>
            <Grid item className={homeBannerStyles.overlayLogoMid} md={2}>
              &nbsp;
            </Grid>
            <Grid className={homeBannerStyles.overlayLogo} item md={5}>
              <img src={overlayLogo} alt="" />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Paper>
  );
};

export default HomeBanner;
